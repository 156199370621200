import React from 'react';
// View
import HomeView from './home-view';

export default function (props) {
  return (
    <div>
        <HomeView />
        
    </div>
  )
}
