import { getClient } from '../../../utils/api';

export const sendNotification = (data, type, captchaToken) => {
    return new Promise(async (resolve, reject) => {
        let apiClient = getClient();
        try {
            let payload={...data, type:type, captchaToken:captchaToken}
            let submissions = await apiClient.service('v1/client/submissions').create(payload);
            resolve(submissions);
        } catch (e) {
            reject(e)
        }
    });
}









