import React,{useEffect} from "react";
import "./styles.css";
export default function (props) {


  
  return (
    <div>
      {props.color!="white" && 
        <div className="spacer-lip spacer-lip-grey"></div>
      }
      {props.color=="white" && 
        <div className="spacer-lip" ></div>
      }
      

      <div className="spacer"></div>
    </div>
  );
}
