import React from "react";
import TestimonialView from "./testimonials-view-new";

export default function (props) {
  return (
    <>
        <TestimonialView/>
    </>
  );
}
