import React, {useEffect, useState} from "react";
import HeaderView from "./header-view-new";
import HeaderMobileView from "./header-mobile-view";


export default function (props) {
  let {mode} = props

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div>
      {width > 850 ? <HeaderView mode={mode}/> : <HeaderMobileView mode={mode}/>}
      
    </div>
  );
}
