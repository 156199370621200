import React from "react";
import Callback from "../drawer";
import imgC2 from "../assets/illustrations/home/1-bg.png";
import imgC3 from "../assets/illustrations/home/2-bg.png";

import "./styles.css";
// import "../Content/styles.css";


import { Button } from "@mui/material";

export default function (props) {
  return (
    <>
      {/* <!-- Content Area -->  */}
      <section className="section-bk-color py-5">
        <div className="container content-height">
          <div className="row justify-content-center align-content-center">
            <div
              className="col-md-6 col-12 order-2 order-lg-1 content-text px-4"
              data-aos="fade-right"
              data-aos-duration="800"
            >
              <div className="position-relative d-flex flex-column justify-content-center h-100 pr-xl-8 pr-0 mt-5 mt-lg-0">
                <h2 className="content-heading  mb-4">
                  Looking for a job? We can help you find the right one for you,
                  your skills and competencies
                </h2>
                <p className="">
                  Let us understand what your Skills, Knowledge, Aptitudes and
                  Competencies are, to profile your behavioural traits and above
                  all to understand what is important to you.
                  <br />
                  <br />
                  Once we understand what your abilities and strengths are and
                  what your priorities are, as well as what makes you happy in a
                  job position or career, we can help you find that job.
                  <br />
                  <br />
                  Experts will meet you and understand what energy, vibe and
                  team you are looking for and what kind of work would make you
                  feel fulfilled.
                  <br />
                  <br />
                  Help us get started in
                  understanding your background, or you can simply request a
                  call-back or meeting.
                </p>
                <div>
                  <Button
                    // className="primary-button"
                    style={{
                      padding: "10px",
                      color: "white",
                      width: "35%",
                      margin: "40px 0 0 0",
                      // backgroundColor: "#f1cb43e5",
                      backgroundColor: "var(--orange)",
                      
                    }}
                    href="/user-intro"
                  >
                    Get To Know Me
                  </Button>
                  <Callback  style={{
                      padding: "5px",
                      color: "black",
                      width: "35%",
                      margin: "40px 0 0 40px",
                      borderBottom: "2px solid var(--orange)",
                      borderRadius: "0px",
                    }}/>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-12 order-1 order-lg-2 ml-5 "
              data-aos="fade-left"
              data-aos-duration="800"
            >
              <div>
                <div className="d-flex justify-content-center mt-4">
                  <div className="d-flex flex-column px-3 my-5 justify-content-end flex-end w-50">
                    <img
                      src={imgC2}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="500"
                      className="tile-image"
                    />
                  </div>
                  <div className="d-flex flex-column px-3 w-lg-auto w-50">
                    <img
                      src={imgC3}
                      alt=""
                      data-aos="zoom-in"
                      data-aos-duration="500"
                      className="tile-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
