import React from "react";
// import { Route, Switch, Redirect } from 'react-router';
// import { BrowserRouter } from 'react-router-dom';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Redirect } from "react-router";
import Home from "../../routes/home";
import Training from "../../routes/training-details";
// import { PublicRoute, PrivateRoute } from "../route";

const About = React.lazy(() => import("../../routes/about"));
const Services = React.lazy(() => import("../../routes/services"));
const Contact = React.lazy(() => import("../../routes/contact"));
const Quiz = React.lazy(() => import("../../routes/quiz"));
const PrivacyPolicy = React.lazy(() => import("../../routes/privacy-policy"));
// const LoginRegister = React.lazy(() => import("../../routes/login-register"));
// const MyAccount = React.lazy(() => import("../../routes/my-account"));
// const UserSetup = React.lazy(() => import("../../routes/user-setup"));
const CompanyRegistration = React.lazy(() =>
  import("../../routes/company-registration")
);

const NotFound = React.lazy(() => import('../../routes/not-found'));
const JobsList = React.lazy(() => import("../../routes/jobs-list"));
const JobDetails = React.lazy(() => import("../../routes/job-details"));

const SuccessJob = React.lazy(() => import("../../routes/success-job"));
const SuccessQuiz = React.lazy(() => import("../../routes/success-quiz"));
const SuccessCompany = React.lazy(() => import("../../routes/success-company"));




export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>

        <Route exact path="/" component={() => <Home />} />
        <Route exact path="/about-us" component={() => <About />} />
        <Route exact path="/contact" component={() => <Contact />} />

        <Route exact path="/services" component={() => <Services />} />
        <Route
          exact
          path="/training-details"
          component={() => <Training />}
        />
        <Route exact path="/user-intro" component={() => <Quiz />} />

        <Route exact path="/job-details" component={() => <JobDetails />} />
        <Route path="/job-search" component={() => <JobsList />} />

        <Route path="/job-success" component={() => <SuccessJob />} />
        <Route path="/quiz-success" component={() => <SuccessQuiz />} />
        <Route path="/company-success" component={() => <SuccessCompany />} />


        {/* <Route exact path="/user-setup" component={() => <UserSetup />} /> */}

        <Route
          exact
          path="/privacy-policy"
          component={() => <PrivacyPolicy />}
        />

        <Route
          exact
          path="/company-registration"
          component={() => <CompanyRegistration />}
        />
        {/* <PublicRoute
          exact
          path="/login-register"
          component={() => <LoginRegister />}
        />
        <PrivateRoute
          exact
          path="/my-account"
          component={() => <MyAccount />}
        /> */}
        
        <Route path="/404" component={()=> <NotFound />} />
        <Route component={() => <NotFound />} />

        {/* </ScrollToTop> */}
      </Switch>
    </BrowserRouter>
  );
}
