const styles = (theme) => ({
  hero: {
    backgroundColor: "rgba(87,65,129,0.05)!important",
    backgroundPosition: "right",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  height:{
    minHeight: "100vh",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
  },
  heroImage:{
    marginTop: "auto",
  }
});
export default styles;
