import * as React from "react";
import {
  IconButton,
  ListItemText,
  List,
  Link,
  ListItem,
  Container,
  Drawer,
  Box,
  Toolbar,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../../components/assets/company-logos/idea-resourcing-logo.png";
import "./styles.css";
import builtByIntelligence from "../../components/assets/company-logos/built-by-intelligence.png";

export default function (props) {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(true);
  };

  const pages = [
    { title: "Jobseeker", link: "/job-search" },
    { title: "Company", link: "/company-registration" },
    { title: "Services", link: "/services" },
    { title: "About", link: "/about-us" },
    { title: "Contact", link: "/contact" },
  ];

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem
          button
          key={"Home"}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          <ListItemText primary={"Home"} />
        </ListItem>
        {pages.map((item, index) => (
          <ListItem
            button
            key={item.title}
            onClick={() => {
              window.location.href = item.link;
            }}
          >
            <ListItemText primary={item.title} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
      <AppBar position={props.mode} className="root">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 10 }}>
              <Link href="/">
                <img src={Logo} alt="IDEA Resourcing" className="header-logo" />
              </Link>
            </Box>
            <Box className="menu-slider" sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                // className={classes.mobileMenuIcon}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon className="menuIcon"/>
              </IconButton>
              <Drawer
                className="menu-sidedrawer"
                anchor={"bottom"}
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
                
              >
                <CloseIcon
                  className="m-3"
                  onClick={() => {
                    setIsDrawerOpen(false);
                  }}
                />

                {list()}

                <img
                  className="builtby-img"
                  src={builtByIntelligence}
                  alt="built by Intelligence"
                />
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
  );
}
