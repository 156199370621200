import React from "react";
import HeaderNav from "../../components/HeaderNav";
import Footer from "../../components/Footer";
import Hero from "./Hero";
// import Brands from "./Brands";
import Categories from "./Categories";
import Jobseeker from "../../components/Content/jobseeker-content";
import CompanyContent from "../../components/Content/company-content";
import Intro from "../../components/Content/intro-content.js";
import Spacer from "../../components/Spacer";
import TestimonialsView from "../../components/Testimonials";

export default function (props) {
  return (
    <>
      <HeaderNav mode={"absolute"} />
      <Hero />
      <Intro />
      <CompanyContent />
      <TestimonialsView />
      <Jobseeker />
      <Spacer color={"white"} />
      <Footer />
    </>
  );
}
