import React, { useEffect, useState, useRef } from "react";

import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import "./styles.css";
import ReCAPTCHA from "react-google-recaptcha";
import { sendNotification } from "../../repository/actions/data/submission";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

function SwipeableEdgeDrawer(props) {
  const { style } = props;
  const [open, setOpen] = React.useState(false);
  const [drawerBleeding, setDrawerBleeding] = useState(0);
  const [token, setToken] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const recaptchaRef_drawer = useRef(null);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const toggleDrawer = (newOpen) => () => {
    if (newOpen) {
      setDrawerBleeding(56);
    } else {
      setDrawerBleeding(-50);
    }
    setOpen(newOpen);
  };

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [notificationSent, setNotificationSent] = useState(false);

  useEffect(() => {
    handleSubmission();
  }, [token]);

  const onSubmitWithReCAPTCHA = async (event) => {
    event.preventDefault();
    const token = await recaptchaRef_drawer.current.execute().then((token) => {
      setToken(token);
    });
    setToken(token);
    handleSubmission();
  };

  const handleSubmission = () => {
    if (token) {
      let isEmpty = false;

      Object.entries(data).map((key) => {
        if (key[1] == "") {
          isEmpty = true;
          setErrorMessage(`Please fill in ${key[0]}`);
        }
      });

      if (!isEmpty) {
        setErrorMessage("");
        setLoading(true);
        let type = "callback-submission";
        let captchaToken = token;

        let payload = {
          fullName: `${data.firstName} ${data.lastName}`,
          email: data.email,
          contactNumber: data.contactNumber,
          isCompany: false,
        };
        sendNotification(payload, type, captchaToken).then((result) => {
          setLoading(false);
          setNotificationSent(true);
          setTimeout(() => {
            toggleDrawer(false);
          }, 5000);
        });
      }
    }
  };

  return (
    <>
      {/* <CssBaseline /> */}
      <Global
        styles={{
          ".drawer  > .MuiPaper-root": {
            height:
              width > 600
                ? `calc(58% - ${drawerBleeding}px)`
                : `calc(70%)!important`,
            width: width > 600 ? `calc(50%)` : `calc(100%)!important`,
            // width:  `calc(50%)` ,
            borderRadius: "10px",
            margin: `auto`,
            overflow: "visible",
            opacity: open ? "1" : "0!important",
          },
        }}
      />

      <Button onClick={toggleDrawer(true)} style={style}>
        Call Me
      </Button>

      <SwipeableDrawer
        // container={container}
        className="drawer"
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Typography sx={{ p: 2, color: "text.secondary" }}>
            Fill in the contact form below
          </Typography>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <form onSubmit={onSubmitWithReCAPTCHA}>
            <ReCAPTCHA
              ref={recaptchaRef_drawer}
              size="invisible"
              sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
            />
            <div className="callback-form row mx-1">
              <div className="col-lg-6 col-12">
                <p>First Name</p>

                <input
                  value={data.firstName}
                  onChange={(event) =>
                    setData({
                      ...data,
                      firstName: event.target.value,
                    })
                  }
                  id="firstName"
                  placeholder="John"
                  type="text"
                />
              </div>
              <div className="col-lg-6 col-12">
                <p>Last Name</p>

                <input
                  value={data.lastName}
                  onChange={(event) =>
                    setData({
                      ...data,
                      lastName: event.target.value,
                    })
                  }
                  id="lastName"
                  placeholder="Doe"
                  type="text"
                />
              </div>
              <div className="col-lg-6 col-12">
                <p>Contact Number</p>

                <input
                  value={data.contactNumber}
                  onChange={(event) =>
                    setData({
                      ...data,
                      contactNumber: event.target.value,
                    })
                  }
                  id="contactNumber"
                  placeholder="+35679123456"
                  type="text"
                />
              </div>
              <div className="col-lg-6 col-12">
                <p>Email</p>

                <input
                  value={data.email}
                  onChange={(event) =>
                    setData({
                      ...data,
                      email: event.target.value,
                    })
                  }
                  id="email"
                  placeholder="john@doe.com"
                  type="email"
                />
              </div>
              <div className="col-12 d-flex  justify-content-end">
                {loading === true && <p>Sending...</p>}
                <p>{errorMessage}</p>

                {notificationSent === true && (
                  <p>
                    Submission Successfull! We will get beack to you soon.
                  </p>
                )}
                <Button
                  type="submit"
                  className="submit_button"
                  style={{
                    padding: "10px",
                    color: "#0d6efd",
                    width: "30%",
                    marginLeft: "auto",
                    backgroundColor: "rgba(25, 118, 210, 0.04)",
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </StyledBox>
      </SwipeableDrawer>
    </>
  );
}

SwipeableEdgeDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default SwipeableEdgeDrawer;
