const styles = theme => ({
    root: {
      backgroundColor: theme.palette.background.default,
      position: 'fixed',
      width: '100%',
      height:'110vh',
      marginTop: '45vh',
      marginBottom: 'auto',
      paddingTop: 0,
      alignItems: 'center',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        paddingTop: 0,
      },
      [theme.breakpoints.up('md')]: {
        paddingTop:0,
      },
    },
    progress: {
      margin: theme.spacing(2),
      marginTop: '55vh',
      color: theme.palette.primary.light
    },
    progressLinear: {
      width: '100%',
      '& > * + *': {
        marginTop: 0,
      }
    },
    rootSmall: {
      backgroundColor: 'none',
      position: 'relative',
      width: '100%',
      marginTop: 'auto',
      marginBottom: 'auto',
      paddingTop: 8,
      alignItems: 'center',
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        paddingTop: 16,
      },
      [theme.breakpoints.up('md')]: {
        paddingTop:16,
      },
      
    },
    progressSmall: {
      margin: theme.spacing(2),
      color: "#d9252a"
    },
    progressSmallLight: {
      margin: theme.spacing(2),
      color: 'white'
    }
  });
  
  export default styles;
  