import React from "react";
import {  Button } from "@mui/material";
import imgManager from "../../components/assets/illustrations/home/manager.png";


import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import "./styles.css";

export default function (props) {


  return (
    <section className=" about-container section-bk-color py-5">
      <div className="container py-5">
        <div className="row align-items-center justify-content-center">
          <div
            className="col-md-6 col-12 "
            data-aos="fade-right"
            data-aos-duration="800"
          >
            <div className="img-container position-relative ">
              <img src={imgManager} alt="" className="about-image w-100" />
              <div className="abs-content pos-abs-br bg-white  pb-1 ">
                <div className="media-about mb-4 mt-2">
                  <span>
                    <DoneRoundedIcon className="check-mark" />
                  </span>
                  <div className="media-body pl-5">
                    <h6 className="accent-heading mb-0 font-size-3 text-uppercase">
                      IDEA Resourcing
                    </h6>
                    <p className="image-text mb-0 font-size-4 text-black-2">
                      52 companies trust us with their employees
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-md-6 col-12"
            data-aos="fade-left"
            data-aos-duration="800"
          >
            <div className="about-content  ">
              <h2 className="about-heading mb-3">
              Committed to identify and train people to meet your holistic
                profile
              </h2>
              <p className=" font-size-content">
                As soon as you get in touch, a Resourcing Expert will call you
                back.
              </p>
              <div className="media-about mb-11">
                <div className="media-icon bg-green-opacity-2 rounded-5">
                  <SearchRoundedIcon className="media-icon-style" />
                </div>
                <div className="media-body ">
                  <h5 className="about-subheading  h5">
                    Resourcing Expert Will Meet You
                  </h5>
                  <p className="mb-0 font-size-content text-default-color">
                    During this meeting (virtual or in person) the resourcing
                    expert will get to know you and your organisation’s holistic
                    profile.
                  </p>
                </div>
              </div>
              <div className="media-about mb-11">
                <div className="media-icon bg-yellow-2-opacity-2 rounded-5">
                  <SearchRoundedIcon className="media-icon-style" />
                </div>
                <div className="media-body">
                  <h5 className="about-subheading  h5">
                    Understand The Holistic Job Profile
                  </h5>
                  <p className="mb-0 font-size-content text-default-color">
                    The Resourcing Expert will understand the ideal and desired
                    character, attitude, behaviour, culture, ethics, skills,
                    knowledge, ability and overall competencies required for
                    the particular role within your particular organisation.
                  </p>
                </div>
              </div>
              <div className="media-about">
                <div className="media-icon bg-red-opacity-2 rounded-5">
                  <SearchRoundedIcon className="media-icon-style" />
                </div>
                <div className="media-body ">
                  <h5 className="about-subheading  h5">
                    Select, Up-Skill, Place
                  </h5>
                  <p className="mb-0 font-size-content text-default-color">
                    From a database of job-seekers, the Resourcing Expert would
                    then select the best candidates for the position and invite
                    you to collaborate in the selection of the best candidate
                    for selection following Competency Mapping and Profiling, and
                    Competency Gaps identified. Selected candidates are then
                    offered coaching and/or upskilling training leading to placement.
                  </p>
                </div>
              </div>
              <div>
                <Button
                  className="primary-button"
                  style={{
                    padding: "10px",
                    color: "white",
                    width: "30%",
                    margin: "40px 0 0 0",
                    backgroundColor: "var(--orange)",
                  }}
                  href="/company-registration"
                >
                  Request Meeting
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
