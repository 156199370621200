import React from "react";

import imgS1 from "../assets/icons/shoot.svg";
import imgS2 from "../assets/icons/user.svg";
import imgS3 from "../assets/icons/heart.svg";

import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
export default function (props) {
  return (
    <section className=" py-5 px-2 section-bk-color">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 ">
            <div className="text-center py-5">
              <h2 className="content-heading ">
                IDEA Resourcing
                <br />A Successful Workplace Made Easy
              </h2>
              <p className="content-subheading">
                IDEA Resourcing is an organisation offering an unparalleled,
                unique service: that of building collaborative relationships
                with organisations with the aim of placing candidates for
                recruitment who are a ‘perfect fit’ for an organisation’s
                holistic needs and company profile.
                <br /> IDEA Resourcing is backed by experts with years of
                industry experience in Recruitment, Up-skilling, Training,
                Coaching, Consultancy and Advisory fields
              </p>
            </div>
          </div>
        </div>
        <div
          className="row justify-content-center py-5"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="col-12 col-md-4 intro-service">
            <div
              className="content-icon mb-4"
              style={{
                boxShadow: "5px 5px 30px -7px var(--orange)",
                backgroundColor: "var(--orange)",
              }}
            >
              <img src={imgS1} alt="" />
            </div>
            <div className="services-content">
              <h3 className="content-icon-text mt-3">Looking for a job?</h3>
              <ul>
                <li>
                  Are you a job seeker who would like to change your job,
                  position or industry, looking for a change or seeking to
                  upgrade your Skills, Knowledge and Competencies ?
                </li>
                <li>
                  Are you a job seeker unsure of what kinds of roles would suit
                  you best and would like to be profiled?
                </li>
                <li>
                  {" "}
                  Would you like to grow and are you ready to explore new career
                  opportunities?
                </li>
              </ul>
            </div>
            <div>
              <Button
                className="secondary-button"
                endIcon={<ArrowForwardIcon />}
                href="/job-search"
                style={{
                  padding: "5px",
                  color: "black",
                  width: "auto",
                  margin: "40px 0 0 0px",
                  borderBottom: "2px solid var(--orange)",
                  borderRadius: "0px",
                }}
              >
                Find Jobs
              </Button>
            </div>
          </div>
          <div className="col-12 col-md-4 intro-service">
            <div
              className="content-icon mb-4 "
              style={{
                boxShadow: "5px 5px 30px -7px var(--mellow)",
                backgroundColor: "var(--mellow)",
              }}
            >
              <img src={imgS2} alt="" />
            </div>
            <div className="services-content">
              <h3 className=" mb-7 content-icon-text">
                Seeking to employ people?
              </h3>
              <p className="font-size-4 text-default-color">
                <ul>
                  <li>Are you looking to employ new team members?</li>
                  <li>
                    Are you looking for candidates who are a perfect fit for
                    your organisational culture and a good job role fit?
                  </li>
                  <li>Are you seeking to reduce your Staff Turnover?</li>
                  <li>
                    Do you need professional support in establishing the
                    Competency Framework for the roles in question?
                  </li>
                </ul>
              </p>
            </div>
            <div>
              <Button
                className="secondary-button"
                endIcon={<ArrowForwardIcon />}
                href="/company-registration"
                style={{
                  padding: "5px",
                  color: "black",
                  width: "auto",
                  margin: "40px 0 0 0px",
                  borderBottom: "2px solid var(--mellow)",
                  borderRadius: "0px",
                }}
              >
                Get People
              </Button>
            </div>
          </div>
          <div className="col-12 col-md-4 intro-service">
            <div
              className="content-icon mb-4"
              style={{
                boxShadow: "5px 5px 30px -7px var(--sandy)",
                backgroundColor: "var(--sandy)",
              }}
            >
              <img src={imgS3} alt="" />
            </div>
            <div className="services-content">
              <h3 className=" mb-7 content-icon-text">Organise training?</h3>
              <p className="font-size-4 text-default-color">
                <ul>
                  <li>
                    Are you looking to organise effective bespoke and
                    tailor-made Training?
                  </li>
                  <li>
                    Do some of your team members need training or upskilling?
                  </li>
                  <li>
                    {" "}
                    Do you need help in identifying ‘who needs what?’ and in
                    designing Training, Development and Coaching to meet Skills
                    / Competency Gaps.
                  </li>
                </ul>
              </p>
            </div>
            <div>
              <Button
                className="secondary-button"
                endIcon={<ArrowForwardIcon />}
                href="/contact"
                style={{
                  padding: "5px",
                  color: "black",
                  width: "auto",
                  margin: "40px 0 0 0px",
                  borderBottom: "2px solid var(--sandy)",
                  borderRadius: "0px",
                }}
              >
                Organise Training
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
