import React, { useEffect, useState, useContext } from "react";
import { getClient } from "../../../utils/api";
import firebaseClient from "../../../utils/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "firebase/auth";
import { logOutWithFirebase } from "../data/authentication";
import Snackbar from "@material-ui/core/Snackbar";

const ContextProps = {
  user: null,
  authenticated: false,
  setUser: {},
  loadingAuthState: false,
  // emailVerified: firebase.User.emailVerified | false,
  // createAuthToken: generateCustomUserAuthToken,
};

export const AuthenticationContext = React.createContext(ContextProps);
const auth = getAuth();

export const AuthenticationProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      let apiClient = getClient();
      if (user) {
        try {
          const idToken = await user.getIdToken();
          const result = await apiClient
            .service("v1/client/users")
            .get(user.uid, {
              query: {
                firebaseToken: idToken,
              },
            });

          setUser({
            ...result,
            idToken,
          });

          setLoadingAuthState(false);
        } catch (e) {
          console.log(e);
        }
      } else {
        setUser(null);
        setLoadingAuthState(false);
      }
    });
  },[]);

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        authenticated: user !== null,
        setUser,
        loadingAuthState,
        // createAuthToken: generateCustomUserAuthToken,
      }}
    >
      {children}
      <Snackbar
        open={openSnackbar}
        severity="error"
        autoHideDuration={6000}
        onClose={handleClose}
        message="Your account has be deactivated."
      ></Snackbar>
    </AuthenticationContext.Provider>
  );
};

export const useAuth = () => useContext(AuthenticationContext);
