import React from "react";
import HeaderNav from "../../components/HeaderNav";
import TrainingDetailsView from "../training-details/training-details-view";
import Spacer from "../../components/Spacer";
import Footer from "../../components/Footer";

import  "./styles.css"
export default function (props) {
  return (
    <div>
      <HeaderNav mode={"absolute"} />
      <TrainingDetailsView />
      <Spacer />
      <Footer />
    </div>
  );
}
