import React from 'react';

// Children
import Routes from './app-routes';
import Loader from '../loader';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..

import 'bootstrap/dist/css/bootstrap.min.css';
export default function () {
AOS.init();

    return (
        <React.Fragment>
            <React.Suspense fallback={<Loader full />}>
                <Routes />
            </React.Suspense>
        </React.Fragment>
    );
}