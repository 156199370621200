import React from "react";
import { initClient } from "../../utils/api";
import { AuthenticationProvider } from "../../repository/actions/global/authentication-provider";
import { GlobalProvider } from "../../repository/actions/global/GlobalContext";

// import { CartProvider } from "repository/stores/global/cart-provider";
// import { CheckoutProvider } from "repository/stores/global/checkout-provider";

// import { QuoteCartProvider } from "repository/stores/global/quote-cart-provider";
// import { MarkupProvider } from "repository/stores/global/markup-provider";
// import { SnackbarProvider } from 'notistack';

// Children
import AppView from "./app-view";

export default function () {
  // Initialise API
  initClient(function (err) {});

  // Return Main App Component
  return (
    // <AuthenticationProvider>
      <GlobalProvider>
       {/* <SnackbarProvider maxSnack={3}  severity="error"> */}
      
        <AppView />
      {/* </SnackbarProvider>  */}
      </GlobalProvider>
    // </AuthenticationProvider>
  );
}
