const assert = require("assert");
const io = require("socket.io-client");
const feathers = require("@feathersjs/client");
// const config = require('config');

let apiClient;

function diff_minutes(dt2, dt1) {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      window.localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return window.localStorage.getItem(key);
    });
  },
  removeItem: function (key) {
    return Promise.resolve().then(function () {
      return window.localStorage.removeItem(key);
    });
  },
};

function initClient(callback) {
  let rememberMe = JSON.parse(
    JSON.stringify(window.localStorage.getItem("rememberMe"))
  );
  let storageType = window.localStorage;

  if (rememberMe) {
    if (rememberMe === true || rememberMe === "true") {
      // Do nothing - let feathers client deal with it
    }
    if (rememberMe === false || rememberMe === "false") {
      // Check timestamp for userId, and remove if over 1 hour
      let loggedInOn = window.localStorage.getItem("loggedInOn");
      let difference = diff_minutes(new Date(), new Date(loggedInOn));

      if (difference > 30) {
        asyncLocalStorage.removeItem("feathers-jwt").then(function () {});
      }
    }
  }

  let APIEndpoint = process.env.REACT_APP_RESOURCING_API;
  const socket = io(APIEndpoint);
  apiClient = feathers();
  apiClient.configure(
    feathers.socketio(socket, {
      timeout: 15000,
    })
  );
  apiClient.configure(
    feathers.authentication({
      storage: storageType,
    })
  );
  return callback(null, apiClient);
}

function getClient() {
  assert.ok(
    apiClient,
    "API has not been initialized. Please called init first."
  );
  return apiClient;
}

module.exports = {
  initClient,
  getClient,
};
