import React, { useEffect, useState, useRef } from "react";
import { Link, TextField, Button } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Logo from "../../components/assets/company-logos/idea-resourcing-logo.png";
import "./styles.css";
import builtByIntelligence from "../../components/assets/company-logos/built-by-intelligence.png";
import { sendNotification } from "../../repository/actions/data/submission";
import DoneIcon from "@mui/icons-material/Done";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ReCAPTCHA from "react-google-recaptcha";

export default function (props) {
  const [isMobile, setIsMobile] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [notificationSent, setNotificationSent] = useState(false);
  const [token, setToken] = useState(null);
  const recaptchaRef_subscribe = useRef(null);

  function handleWindowSizeChange() {
    if (window.innerWidth > 790) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  
  useEffect(() => {
    handleSubscribe();
  }, [token]);

  const onSubmitWithReCAPTCHA = async (event) => {
    event.preventDefault();
    const token = await recaptchaRef_subscribe.current.execute().then((token) => {
      setToken(token);
    });
    setToken(token);
    handleSubscribe();
  };

  const handleSubscribe = () => {
    if (token) {

    let type = "subscription-submission";
    let captchaToken = token;
    let payload = {
      fullName: `subscription`,
      email: email,
      contactNumber: `subscription`,
      isCompany: false,
    };
    sendNotification(payload, type, captchaToken).then((result) => {
      setLoading(false);
      setNotificationSent(true);
    });
  }
  };

  return (
    <>
      <footer className="footer bg-ebony-clay dark-mode-texts">
        <div id="footer-top-section" className="footer-container">
          <div className="footer-cta ">
            <div className="row justify-content-between ">
              <div className="col-md-5 col-12">
                <div className="mb-0 text-center">
                  <h2 className="footer-heading font-size-8 mb-2">
                    A Successful Workplace Made Easy
                  </h2>
                  <p className="footer-subheading mb-0">
                    Drop us your email to get onboard!
                  </p>
                </div>
              </div>
              <div className="col-md-5 col-12">
                <form id="subscription" onSubmit={onSubmitWithReCAPTCHA}>
                  <ReCAPTCHA
                    ref={recaptchaRef_subscribe}
                    size="invisible"
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
                  />
                  <div className="btns d-flex justify-content-xl-end justify-content-center align-items-xl-center flex-wrap h-100  mx-n4">
                    <TextField
                      id="subscribe"
                      sx={{ m: 1, width: "25ch" }}
                      onChangeCapture={(event) => {
                        setEmail(event.target.value);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!notificationSent && (
                              <Button
                                disabled={email == ""}
                                edge="end" type="submit"
                                className="subscribe_button"
                              >
                                Join
                              </Button>
                            )}
                            {notificationSent && !loading && (
                              <DoneIcon className="subscribe_loading_done" />
                            )}
                            {!notificationSent && loading && (
                              <MoreHorizIcon className="subscribe_loading_done" />
                            )}
                          </InputAdornment>
                        ),
                        disableUnderline: true,
                      }}
                      className="subscribe"
                      placeholder="Email"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className="footer-container-main">
          {!isMobile && (
            <div className="row">
              <div className="col-md-4 col-sm-6 mb-lg-0 ">
                <div>
                  <Link href="/">
                    <img
                      src={Logo}
                      className="footer-logo align-self-center mr-3"
                      alt=""
                    />
                  </Link>
                  <div className="media-body pl-5">
                    <p className="mb-0 font-size-4 text-white">Contact us at</p>
                    <Link href="mailto:resourcing@ideamalta.com" underline="always">
                      <a className="footer-page-links mb-0 font-size-4 font-weight-bold">
                      resourcing@ideamalta.com
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-md-3 ">
                <div className="row justify-content-end">
                  <div className="col-lg-3 col-md-6 col-sm-3 col-6 offset-md-3">
                    <div className="footer-widget widget2 mb-md-0 mb-13">
                      <p className="widget-title font-size-4 text-gray mb-md-8 mb-7">
                        Pages
                      </p>
                      <ul className="widget-links pl-0 list-unstyled list-hover-primary">
                        <li className="footer-pages">
                          <Link href="/about-us" underline="hover">
                            <a className="footer-page-links ">About us</a>
                          </Link>
                        </li>
                        <li className="footer-pages">
                          <Link href="/services" underline="hover">
                            <a className="footer-page-links ">Services</a>
                          </Link>
                        </li>
                        <li className="footer-pages">
                          <Link href="/contact" underline="hover">
                            <a className="footer-page-links ">Contact us</a>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-3 col-6 ">
                    <div className="footer-widget widget3 mb-sm-0 mb-13">
                      <p className="widget-title font-size-4 text-gray mb-md-8 mb-7">
                        IDEA Group
                      </p>
                      <ul className="widget-links pl-0 list-unstyled list-hover-primary">
                        <li className="footer-pages">
                          <Link
                            underline="hover"
                            href="https://ideaeducation.com.mt/"
                            target="_blank"
                          >
                            <a className="footer-page-links ">IDEA Academy</a>
                          </Link>
                        </li>
                        <li className="footer-pages">
                          <Link
                            underline="hover"
                            href="https://ideamalta.com/advisory-services/"
                            target="_blank"
                          >
                            <a className="footer-page-links ">IDEA Advisory</a>
                          </Link>
                        </li>
                        <li className="footer-pages">
                          <Link
                            underline="hover"
                            href="https://ideamalta.com/intelligence-services/"
                            target="_blank"
                          >
                            <a className="footer-page-links ">
                              IDEA Intelligence
                            </a>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isMobile && (
            <div className="row ">
              <div className="col-md-12 col-12 text-center">
                <div>
                  <Link href="/">
                    <img
                      src={Logo}
                      className="footer-logo align-self-center mr-3"
                      alt=""
                    />
                  </Link>
                </div>
              </div>
              <div className="col-md-12 col-12 text-center">
                <div className="footer-widget">
                  <p className="widget-title text-center">Pages</p>
                  <ul className="condition-list  pl-0 list-unstyled list-hover-primary">
                    <li className="footer-pages">
                      <Link href="/about-us" underline="hover">
                        <a className="footer-page-links mx-2">About us</a>
                      </Link>
                    </li>
                    <li className="footer-pages">
                      <Link href="/services" underline="hover">
                        <a className="footer-page-links mx-2">Services</a>
                      </Link>
                    </li>
                    <li className="footer-pages">
                      <Link href="/contact" underline="hover">
                        <a className="footer-page-links mx-2">Contact</a>
                      </Link>
                    </li>
                    <li className="footer-pages">
                      <Link href="https://ideamalta.com" underline="hover">
                        <a className="footer-page-links mx-2">IDEA Group</a>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>

        <hr width="95%" className="mx-auto" />

        <div className="footer-container">
          <div className="row">
            <div className="col-md-4 col-12">
              <p className="text-white small">
                All rights reserved by IDEA Group 2022 ©
              </p>
            </div>
            <div className=" col-12  col-md-4">
              <ul className="condition-list widget-links list-unstyled list-hover-primary">
                <li>
                  <Link href="/privacy-policy" underline="hover">
                    <a className="footer-page-links small mx-2">
                      Privacy Policy
                    </a>
                  </Link>
                </li>
                {/* <li>
                  <Link href="/#" underline="hover">
                    <a className="footer-page-links small  mx-2">
                      Terms &amp; Conditions
                    </a>
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className="col-12 col-md-4 built-by-image">
              <img
                src={builtByIntelligence}
                className="footer-builtby"
                alt="built by IDEA Intelligence"
              />
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
