import React from 'react';
// View
import HeroView from './double-view';
export default function (props) {

  return (
    <div>
        <HeroView />
    </div>
  )
}
