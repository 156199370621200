const styles = (theme) => ({
  root: {
    boxShadow: "none!important",
    backgroundColor: "transparent!important",
    // position: "absolute",
  },
  rightToolbar: {
    marginLeft: "auto",
    marginRight: 50,
    "& a": {
      color: "rgba(87,65,129,1)!important",
    },
    display: "flex!important",
  },

  spacing: {
    margin: "0 10px!important",
    backgroundColor: "rgba(0,0,0,0.02)!important",
    display: "flex!important",
  },

  profileMenu: {
    "&.MuiPopover-paper": {
      position: "relative",
    },
    "&.MuiPopover-root": {
      top: "30px!important",
      //   minWidth:" 227px",
      // maxWidth: "227px",
      boxShadow: "0 52px 54px rgb(65 62 101 / 30%)",
    },

    "&.MuiMenuItem-root": {
      // width: "150px!important",
      // minWidth: "150px!important",
      fontSize: "12px",
    },
    "& .MuiMenu-paper": {
      boxShadow: "0 20px 50px rgb(65 62 101 / 20%)!important",
    },
    "& .MuiButtonBase-root": {
      backgroundColor: "rgba(0,0,0,0.02)!important",
    },
    "& .MuiListItem-button": {
      backgroundColor: "rgba(255,255,255,1)!important",
    },
    "& .MuiIconButton-root": {
      color: "rgba(87,65,129,1)!important",
    },
  },
  // mobileMenuIcon:{
  //   color: "rgb(200,200,0)"
  // }
  
});

export default styles;
