import React from "react";
import { Link } from "@mui/material";
import "./styles.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import WorkIcon from "@mui/icons-material/Work";

const qs = require("query-string");

export default function (props) {
  const parsed = qs.parse(window.location.search);
  const trainingTitle = parsed.tid;

  let training = [
    {
      title: "Human Resources",
      attributes: [
        {
          topic: "Recruitment & Profiling",
          topics: [
            "Conducting Effective Interviews",
            "Profiling for Role Setting and Fit ",
            "Induction Programmes ",
            "The Probationary Period & how best to use it",
            "Conducting an Exit Interview",
            "Recruitment Manager Skills",
          ],
        },
        {
          topic: "HRD: Training & Development",
          topics: [
            "Training Needs Analysis",
            "Skill Gap Analysis",
            "Competency Frameworks",
            "Becoming a Coach",
            "Train the Trainer Programme",
            "Train the Industrial Trainer and Technician",
            "Knowledge Management and the Learning Organisation",
            "Training Manager Skills ",
          ],
        },
        {
          topic: "Feedback, Reviews & Performance Management",
          topics: [
            "Communicating in a Review or Performance Appraisal",
            "Holding a Feedback Session",
            "Implementing a Performance Management System",
            "Conducting a Performance Appraisal",
            "Skills for the Performance Manager Specialist",
          ],
        },
        {
          topic: "Motivation, Employee Engagement & Involvement",
          topics: [
            "Motivational Strategies",
            " Enhancing Employee Involvement",
            "Company Culture",
          ],
        },
        {
          topic: "HR Related Competency Skills",
          topics: [
            "Acquiring Empathy",
            "Active and Effective Listening Skills",
            "HR Presentation Skills",
            "Strong Rapport Building Skills",
          ],
        },
      ],
    },

    {
      title: "Finance & Management",
      attributes: [
        {
          topic: "Finance & Financial Management",
          topics: [
            "Cost-Benefit Analysis",
            "Elements of Business Finance ",
            "Managerial Accounting",
            "Practical Book-Keeping and Accounts ",
          ],
        },
        {
          topic: "Management Concepts & Tools",
          topics: [
            "Managing for Inclusiveness, across Cultural Diversity ",
            "Becoming a Supervisor",
            "Becoming a Competent Manager",
            "Building a Winning Team",
            "Business Process Re-Engineering",
            "Strategic Business Management",
            " Preparing a Business Plan",
            " Change Management",
          ],
        },
        {
          topic: "Project Management",
          topics: [
            "Introduction to Project Management",
            "Managing Projects",
            "Managing the Project Team ",
            "Managing Quality",
            "Managing Risk",
          ],
        },
        {
          topic: "Quality Management",
          topics: [
            " Quality Management for Business Excellence",
            "Advanced Quality Management ",
            "Value Added Auditing",
            "Lean Six Sigma to Remain Competitive",
            "Statistical Process Control",
            "Problem Solving and Conflict Management ",
          ],
        },
      ],
    },

    {
      title: "Life & Professional Personal Skills",
      attributes: [
        {
          topic: "Communication Skills",
          topics: [
            "Email writing",
            "Communication Skills",
            "Telephone Handling",
            "Business English",
            "Giving and Receiving Feedback",
            "Negotiation Techniques and Argumentation",
            "Acquiring Verbal Intelligence",
          ],
        },
        {
          topic: "Personal Business and Professional Skills",
          topics: [
            "Presentation Skills",
            "Public Speaking Skills",
            "Reseach Models",
            "Critical Thinking",
            "Decision Making Techniques",
            "Conflict Management",
          ],
        },
        {
          topic: "Personal Life Skills",
          topics: [
            "Problem Solving ",
            "Time Management & Organisation",
            "Communication Skills",
            "Research Methods",
            "Stress Management Training Workshops",
            "Learning to Focus ",
          ],
        },
      ],
    },

    {
      title: "NLP Neuro Linguistic Programming",
      attributes: [
        {
          topic: "NLP Personal Coaching Courses",
          topics: [
            "NLP : An Introduction to Personal Empowerment ",
            "NLP Personal Power: Personal Empowerment Level 1",
            "NLP Personal Power: Personal Empowerment Level  2",
            "Positive Programming for Success",
            "Well-Formed Outcomes and Powerful Goal Setting",
            "Boosting Self-Beliefs and Confidence Workshop - Gaining Personal Power and Assertiveness",
            "Mind-Process Re-Engineering and NLP Values Work",
            "NLP Verbal Intelligence and Linguistic Re-Framing",
            "NLP Visualisation and Mind-Programming",
            "NLP Motivation Workshops: Personal Motivation Strategies for Self and Others",
          ],
        },
        {
          topic: "NLP Sales Related Training Courses",
          topics: [
            "NLP Sales Success",
            "NLP Power for Rapport Building",
            "NLP Communication Power",
          ],
        },
        {
          topic: "NLP for Healthcare Training",
          topics: ["NLP Empowerment Coaching for Healthcare Professionals"],
        },
        {
          topic: "NLP HR & Management Related Roles",
          topics: [
            "Powerful NLP based Profiling and Screening",
            "Coaching & Development using NLP Projection ",
            "Learning Styles for Trainers and Presenters",
            "NLP Tools for Reviews and Appraisals",
          ],
        },
        {
          topic: "Sports & Performance NLP Training",
          topics: [
            "NLP Power for Sports Performance",
            "NLP Performance Training ",
          ],
        },
      ],
    },
    {
      title: "Sales Retail & Customer Approach",
      attributes: [
        {
          topic: "Sales Training & Retail",
          topics: [
            "The Sales Process: A Foundation Course",
            "Advanced Sales Course",
            "Building your Sales Portfolio: Working with Features and Benefits",
          ],
        },
        {
          topic: "Sales Management & Leadership",
          topics: [
            "Becoming a Sales Supervisor",
            "Becoming a Sales Manager",
            "Training Retail Shop Staff",
          ],
        },
        {
          topic: "Sales Psychology",
          topics: [
            "Client Profiling for Customised and Powerful Sales Approaches",
            "Understanding Client Psychology and Sales Marketing ",
          ],
        },
        {
          topic: "Personal Sales Training Skils",
          topics: [
            "Building Resilience: Managing our own Motivational Strategies",
            "Self-Confidence and Self Belief: Working on our Self-Image",
            "Presentation Skills for Sales Professionals",
          ],
        },
        {
          topic: "Sales Communication, Customer Care and Customer Approach",
          topics: [
            "Customer Care and Customer Approach",
            "Telephone Skills for Sales and Customer Approach ",
            "Building Strong Rapport for Sales",
            "Finding out a Client's Needs: Questioning Techniques and Active Listening",
            "Sales Argumentation and Negotiation",
            "Handling Challenging and Negative Clients in Sales; Complaint Handling",
            "Powerful Sales Language and the Power of Influence ",
          ],
        },
        {
          topic: "Property Sales",
          topics: [
            "Becoming a Property Consultant",
            "Property Consultancy Advanced",
            "Improve your Sales Closure Ratio",
          ],
        },
      ],
    },
  ];

  let index = training
    .map(function (e) {
      return e.title;
    })
    .indexOf(trainingTitle.replace("-", "&").replace("%20", " "));

  return (
    <section className="bk-color py-5">
      <div className="training-details-section bg-default-1 pt-5">
        <div className="container pt-5">
          <div
            className="row justify-content-center"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {/* <!-- back Button --> */}
            <div className="col-xl-10 col-lg-11 mt-4">
              <div className="mb-9">
                <Link href="/services#training" underline="none">
                  <ChevronLeftIcon className="back_arrow" />
                  <span className="back_arrow_text text-uppercase font-size-3 font-weight-bold text-gray">
                    Back to training board
                  </span>
                </Link>
              </div>
            </div>
            {/* <!-- back Button End --> */}
            <div className="col-xl-9 col-lg-11 ">
              <div className="details_card bg-white rounded-4 border border-mercury shadow-9">
                {/* <!-- Single Featured Job --> */}
                <div className=" pb-3  border-width-1 border-default-color light-mode-texts">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="media align-items-center">
                        <div className="training_card_headers m-0">
                          <h3 className="details_card_heading mb-0">
                            {trainingTitle.replace("-", "&")}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="job-details-content  border-bottom border-width-1 border-default-color light-mode-texts">
                  <div className="my-3 row">
                    <p className="font-size-4 text-black-2 ">
                      Here below is a sample of Training Courses related to{" "}
                      {trainingTitle} which IDEA Resourcing can organise for
                      your organisation, to suit your industry-specific and
                      organisational Training Needs.
                    </p>
                    {trainingTitle == "NLP Neuro Linguistic Programme" && (
                      <p>
                        NLP refers to Neuro Linguistic Programming, a powerful
                        Training Methodology based on a science developed from
                        Cognitive Psychology, the study of subjective
                        interpretation, personal heuristics and the personal
                        strategies for success which allows us to change our
                        personal mind processes (referred to as
                        Mind-Re-Engineering) for the achievement of different or
                        better results. NLP is a powerful training method which
                        empowers professionals to re-engineer their own thinking
                        strategies.
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <div className="training_details_attributes mt-2 row">
                    <div className="col-xl-11 col-md-12">
                      {training[index].attributes.map((topic) => {
                        return (
                          <div className="my-5">
                            <div className="row">
                              <div className="media justify-content-md-start">
                                <div className="mr-5">
                                  <WorkIcon className="details_icon" />
                                </div>
                                <p className="details_attributes">
                                  {topic.topic}
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <ul className="tags d-flex list-unstyled flex-wrap pr-sm-25 pr-md-0">
                                {topic.topics.map((item) => {
                                  return <li className="single_tag">{item}</li>;
                                })}
                              </ul>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
