import React, { useContext } from "react";

// import AppBar from "@mui/material/AppBar";
import AppBar from "@mui/material/AppBar";

import { makeStyles } from "@material-ui/core/styles";
import {
  Link,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Drawer ,
  Menu,
  MenuItem,
  Container,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import styles from "./header-styles";
import Logo from "../../components/assets/company-logos/idea-resourcing-logo.png";

import { AuthenticationContext } from "../../repository/actions/global/authentication-provider";

const pages = [
  { title: "Jobseeker", link: "/job-search" },
  { title: "Company", link: "/company-registration" },
  { title: "Services", link: "/services" },
  { title: "About", link: "/about-us" },
  { title: "Contact", link: "/contact" },
];
// const settings = ["Profile", "Logout"];
const useStyles = makeStyles(styles);

const ResponsiveAppBar = (props) => {
  const classes = useStyles();

  const { user } = useContext(AuthenticationContext);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [menuItems, setMenuItems] = React.useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  function handleClick(event, tab) {
    if (tab == "Jobseeker") {
      setMenuItems(jobseekerItems);
    }
    if (tab == "Company") {
      setMenuItems(companyItems);
    }
    if (tab == "Services") {
      setMenuItems(servicesItems);
    }

    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }
  function handleClose() {
    setAnchorEl(null);
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = (setting) => {
    window.location.href = `/my-account`;
    if (setting == "Profile") {
      console.log("redirecting");
    }
    setAnchorElUser(null);
  };

  // Drawer
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(true);
  };
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const jobseekerItems = [
    {
      title: "Quick Quiz",
      path: "/user-intro",
      cName: "dropdown-link",
    },
    {
      title: "Jobs Available",
      path: "/job-search",
      cName: "dropdown-link",
    },
    {
      title: "Leave Us Your Details",
      path: "/contact",
      cName: "dropdown-link",
    },
  ];

  const companyItems = [
    {
      title: "Contact Us Now",
      path: "/contact",
      cName: "dropdown-link",
    },
    {
      title: "",
      path: "/consulting",
      cName: "dropdown-link",
    },
  ];

  const servicesItems = [
    {
      title: "Bespoke Training",
      path: "/training",
      cName: "dropdown-link",
    },
    {
      title: "Skills Gap Analysis",
      path: "/consulting",
      cName: "dropdown-link",
    },
    {
      title: "Competency Framework",
      path: "/consulting",
      cName: "dropdown-link",
    },
  ];

  return (
    // <HideOnScroll {...props}>
    <AppBar position={props.mode} className={classes.root}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 10 }}>
            <Link href="/">
              <img src={Logo} alt="IDEA Resourcing" className="header-logo m-4" />
            </Link>
          </Box>
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
            LOGO
          </Typography> */}
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <section className={classes.rightToolbar}>
              {pages.map((page) => {
                // let haspopup = false;
                // if (page.title == "Services") {
                //   haspopup = true;
                // }
                return (
                  <>
                    
                    <Button
                      key={page.title}
                      className={classes.spacing}
                      href={page.link}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      {page.title}
                    </Button>
                    {/* )} */}
                  </>
                );
              })}
            </section>
          </Box>

          
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              className={classes.mobileMenuIcon}
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={toggleDrawer(true)}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor={"right"}
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
            >
              {menuItems.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    className={item.cName}
                    to={item.path}
                    onClick={() => {
                      window.location.href = item.path;
                    }}
                  >
                    {item.title}
                  </MenuItem>
                );
              })}
            </Drawer>

            {/* <Menu
              id="menu-appbar"
              className={classes.profileMenu}
              anchorEl={anchorElNav}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              cla
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.title}
                  onClick={handleCloseNavMenu}
                  className={classes.profileMenu}
                >
                  <Typography textAlign="center">{page.title}</Typography>
                </MenuItem>
              ))}
            </Menu> */}
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              md={{ top: "45px" }}
              className={classes.profileMenu}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              MenuListProps={{ onMouseLeave: handleClose }}
            >
              {menuItems.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    className={item.cName}
                    to={item.path}
                    onClick={() => {
                      window.location.href = item.path;
                    }}
                  >
                    {item.title}
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
    // </HideOnScroll>
  );
};
export default ResponsiveAppBar;
