import React from "react";
import { Button } from "@mui/material";

import "./styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import test1 from "../assets/testimonials/test1.png";
import test2 from "../assets/testimonials/test2.png";
import test3 from "../assets/testimonials/test3.png";
import test4 from "../assets/testimonials/test4.png";
import test5 from "../assets/testimonials/test5.png";
import test6 from "../assets/testimonials/test6.png";

export default function (props) {
  let settings = {
    dots: false,
    arrows: false,
    fade: true,
    infinite: true,
    pauseOnHover: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className="testimonial-container p-md-5">
      <div className="container-fluid p-md-4">
        <div className="row">
          <div className="col-md-4 col-12 ">
            <h2 className="testimonial-header mb-4 text-center text-md-start mt-5 mb-5 p-3">
              Join Our <br />
              Satisfied Clients{" "}
            </h2>
          </div>
          <div className="col-md-8 col-12">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="testimonial-panel">
                    <Slider {...settings} className="offset-container">
                      <div style={{border:"none", outline:"none"}}>
                        <img src={test2} width="100%"  style={{borderRadius:"20px",border:"none", outline:"none"}}/>
                      </div>
                      <div>
                        <img src={test1} width="100%" style={{borderRadius:"20px",border:"none", outline:"none"}}/>
                      </div>
                    </Slider>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="testimonial-panel">
                    <Slider {...settings} className="offset-container">
                      <div>
                        <img src={test3} width="100%" style={{borderRadius:"20px",border:"none", outline:"none"}}/>
                      </div>
                      <div>
                        <img src={test3} width="100%" style={{borderRadius:"20px",border:"none", outline:"none"}}/>
                      </div>
                    </Slider>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="testimonial-panel">
                    <Slider {...settings} className="offset-container">
                      <div>
                        <img src={test6} width="100%" style={{borderRadius:"20px",border:"none", outline:"none"}}/>
                      </div>
                      <div>
                        <img src={test6} width="100%" style={{borderRadius:"20px",border:"none", outline:"none"}}/>
                      </div>
                    </Slider>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="testimonial-panel">
                    <Slider {...settings} className="offset-container">
                      <div>
                        <img src={test5} width="100%" style={{borderRadius:"20px",border:"none", outline:"none"}}/>
                      </div>
                      <div>
                        <img src={test4} width="100%" style={{borderRadius:"20px",border:"none", outline:"none"}}/>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
