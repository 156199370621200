import { getClient } from '../../../utils/api';

export const getJobs = () => {
    return new Promise(async (resolve, reject) => {
        let apiClient = getClient();
        try {
            let jobs = await apiClient.service('v1/client/jobs').find();
            resolve(jobs);
        } catch (e) {
            reject(e)
        }
    });
}



export const getJob = (id) => {
    return new Promise(async (resolve, reject) => {
        let apiClient = getClient();
        try {
            let jobs = await apiClient.service('v1/client/jobs').get(id);
            resolve(jobs);
        } catch (e) {
            reject(e)
        }
    });
}



export const editJob = (id, payload, authToken) => {
    return new Promise(async (resolve, reject) => {
        let apiClient = getClient();
        try {
            let job = await apiClient.service('v1/client/jobs').patch(id, payload, {
                query: {
                  firebaseToken: authToken,
                }
              });
            resolve(job);
        } catch (e) {
            reject(e)
        }
    });
}


