import React from "react";
import {  Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./hero-styles";
import "./styles.css";

// import Autocomplete from "@material-ui/lab/Autocomplete";
// import InputAdornment from "@mui/material/InputAdornment";
// import SearchIcon from "@mui/icons-material/Search";
// import algoliaJobsIndex from "../../../clients/algolia/JobsIndex";
import jobseeker from "../../../components/assets/illustrations/home/1-blop.png";
import company from "../../../components/assets/illustrations/home/2-blop.png";

// import PopupView from "../Popup";


const useStyles = makeStyles(styles);

export default function (props) {
  const classes = useStyles();

  return (
    <section className={classes.hero}>
      {/* <PopupView/> */}
      <div className="container-fluid hero-container">
        <div className="row height">
          <div
            className="col-12 col-md-6"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            <img
              src={jobseeker}
              alt=""
              className="jobseeker-image"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="1000"
            />
            <h1 className="hero_heading">
              Looking for the perfect{" "}
              <span className="coloured-header">job</span> for you?
            </h1>
            <p>Let us help you find your next job opportunity</p>

            <Button
              edge="end"
              className="hero-button"
              href="/job-search"
            >
              Find me a Job
            </Button>
          </div>
          <div
            className="col-sm-12 col-md-6"
            data-aos="fade-left"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            <div
              className="d-flex justify-content-end mt-3"
              data-aos="fade-left"
              data-aos-duration="1000"
              data-aos-delay="1000"
            >
                <img
                  src={company}
                  alt=""
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  className="company-image"
                />
              </div>
             
            <h1 className="hero-heading-company">
              Looking to employ <span className="coloured-header">trained</span> and
              skilled people for <span className="coloured-header">the job</span>?
            </h1>
            <div className="alignment-end">
              <p>Let us help you find the right people</p>
              <Button
                edge="end"
                className="hero-button"
                href="/company-registration"
                onClick={()=>{window.location.href="/company-registration"}}
              >
                Find People
              </Button>
            </div>
           
          </div>
        </div>
      </div>
    </section>
  );
}
